import axios from "axios";
const {
  PLAY_SLOT_MACHINE,
  PLAY_FROLIC_GAMES,
} = require("../../../app/common/event_names");

async function init() {
  try {
    const userDetailData = await fetchUserDetail();
    if (!userDetailData || !userDetailData.payload || !userDetailData.payload.user) {
      console.error("Invalid userDetailData response");
      return;
    }

    const userData = userDetailData.payload.user;
    let isBlockUser = userData.blocked;
    if (isBlockUser) {
      return;
    }
    window.FPI.extension.register(`#fynd-engage-rewards-panel`, {
      mounted() {
        if (window.FPI) {
          window.FPI.state.user.subscribe(() => {
            createDialogWithRewards();
          });
        }
      },
    });
  } catch (error) {
    console.error("Error in init function:", error);
  }
}

async function fetchUserDetail() {
  const apiUrl = `/ext/fynd-engage/application/engage/v1.0/user`;
  try {
    const response = await axios.get(apiUrl);
    return response.data;
  } catch (error) {
    console.error("Error fetching user existence data (In fetchUserDetail):", error);
    return null;
  }
}

// Call the `init` function to execute the logic
init();



function createDialogWithRewards() {
  // Fetch rewards data
  function fetchRewardsData() {
    const apiUrl = `/ext/fynd-engage/application/engage/v1.0/reward`;
    return fetch(apiUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .catch((error) => {
        console.error("Error fetching rewards:", error);
        return null;
      });
  }

  // Fetch user existence data
  function fetchUserExistData() {
    const apiUrl = `/ext/fynd-engage/application/engage/v1.0/user/exist`;
    return fetch(apiUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .catch((error) => {
        console.error("Error fetching user existence data:", error);
        return null;
      });
  }

  // Fetch user data
  function fetchUserData() {
    const apiUrl = `/ext/fynd-engage/application/engage/v1.0/user`;
    return fetch(apiUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
        return null;
      });
  }

  // Function to darken a hex color
  function darkenHexColor(hexColor, percent) {
    const num = parseInt(hexColor.replace("#", ""), 16);
    const amt = Math.round(2.55 * percent);
    const R = (num >> 16) - amt;
    const G = ((num >> 8) & 0x00ff) - amt;
    const B = (num & 0x0000ff) - amt;
    return (
      "#" +
      (
        0x1000000 +
        (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
        (G < 255 ? (G < 1 ? 0 : G) : 255) * 0x100 +
        (B < 255 ? (B < 1 ? 0 : B) : 255)
      )
        .toString(16)
        .slice(1)
    );
  }

  // Fetch all necessary data
  Promise.all([fetchRewardsData(), fetchUserExistData()])
    .then(([rewardsData, userExistData]) => {
      if (!rewardsData) {
        console.error("Failed to fetch rewards data");
        return;
      }

      // Only call fetchUserData if user exists
      if (userExistData && userExistData.userExist) {
        return Promise.all([rewardsData, fetchUserData()]);
      } else {
        return [rewardsData, null];
      }
    })
    .then(([rewardsData, userData, userExistData]) => {
      const themeColor = rewardsData.rewardPanel.themeColor;
      const fontFamily = rewardsData.rewardPanel.fontFamily;
      const programName = rewardsData.rewardPanel.programName;
      const hoverColor = darkenHexColor(themeColor, 20);

      const styles = `
        /* Main dialog container */
        #rewards-dialog {
          height: 100%;
          position: fixed;
          bottom: 80px;
          right: 20px;
          width: 320px;
          max-height: 400px;
          overflow-y: auto;
          border: 1px solid #e0e0e0;
          background-color: #fff;
          box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
          z-index: 1000;
          border-radius: 12px;
          opacity: 0;
          transform: scale(0.9);
          visibility: hidden;
          transition: transform 0.3s ease, opacity 0.3s ease, visibility 0s linear 0.3s;
          font-family: '${fontFamily}', sans-serif;
        }

        /* When dialog is visible */
        #rewards-dialog.show {
          opacity: 1;
          transform: scale(1);
          visibility: visible;
          transition-delay: 0s;
        }

        /* Header container with gradient background */
        #header-container {
          background: ${themeColor};
          padding: 20px; /* Increase padding for better spacing */
          border-radius: 12px 12px 0 0;
          margin-bottom: 12px;
          text-align: center;
          position: relative;
          display: flex;
          align-items: center; /* Center items vertically */
          justify-content: center; /* Center items horizontally */
          flex-direction: row; /* Use row for image and text side by side */
        }

        #header-container h2 {
          font-size: 1.2em;
          color: #ffffff;
          margin: 0;
          margin-left: 10px; /* Add space between image and text */
          font-weight: normal;
        }

        /* Header image */
        #header-image {
          width: 40px;
          height: 40px;
          margin-right: 10px;
          border-radius: 50%;
          flex-shrink: 0; /* Prevent image from shrinking */
        }

        /* Back arrow button style */
        .back-arrow {
          position: absolute;
          left: 10px;
          top: 50%;
          transform: translateY(-50%);
          background: transparent;
          border: none;
          cursor: pointer;
          display: none;
          padding: 0;
        }

        .back-arrow svg {
          width: 22px;
          height: 22px;
          stroke: #ffffff;
        }

        /* Member Card style */
        .memberCard {
          padding: 16px;
          margin: 8px;
          border: 1px solid #dcdcdc;
          border-radius: 10px;
          background-color: #f9f9f9;
          box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
          display: flex;
          flex-direction: column;
          align-items: center;
          transition: transform 0.3s ease, box-shadow 0.3s ease;
        }

        .memberCard:hover {
          transform: translateY(-4px);
          box-shadow: 0 3px 12px rgba(0, 0, 0, 0.1);
        }

        /* Card title */
        .memberCard h6 {
          font-size: 0.95em;
          color: #333333;
          margin-bottom: 8px;
          font-weight: normal;
        }

        /* Card description */
        .memberCard p {
          font-size: 0.85em;
          color: #666666;
          margin-bottom: 12px;
          text-align: center;
          font-weight: normal;
        }

        /* Card button style */
        .memberCard-button {
          background-color: ${themeColor};
          color: #ffffff;
          border: none;
          border-radius: 6px;
          padding: 8px 16px;
          cursor: pointer;
          font-size: 0.85em;
          transition: background-color 0.3s ease, transform 0.2s ease;
          font-weight: normal;
        }

        .memberCard-button:hover {
          background-color: ${hoverColor};
          transform: translateY(-2px);
        }

        /* Card style */
        .card {
          display: flex;
          align-items: center;
          padding: 12px;
          margin: 8px;
          border: 1px solid #dcdcdc;
          border-radius: 10px;
          background-color: #ffffff;
          box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
          transition: transform 0.3s ease, box-shadow 0.3s ease;
          justify-content: space-between; /* Ensure the arrow is inside the card */
        }

        .card:hover {
          transform: translateY(-4px);
          box-shadow: 0 3px 12px rgba(0, 0, 0, 0.1);
        }

        /* Card text container */
        .card-content {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          margin-left: 8px;
          max-width: 200px; /* Ensures the arrow stays inside */
        }

        /* Card title */
        .card h6 {
          font-size: 0.95em;
          color: #333333;
          margin-bottom: 5px;
          font-weight: normal;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        /* Card description */
        .card p {
          font-size: 12px;
          color: #666666;
          margin: 0;
          text-align: left;
          font-weight: normal;
          cursor: pointer;
        }

        /* Card button style */
        .card-button {
          background-color: ${themeColor};
          color: #ffffff;
          border: none;
          border-radius: 6px;
          padding: 8px 16px;
          cursor: pointer;
          font-size: 0.85em;
          transition: background-color 0.3s ease, transform 0.2s ease;
          font-weight: normal;
        }

        .card-button:hover {
          background-color: ${hoverColor};
          transform: translateY(-2px);
        }

        /* Sign-in link style */
        .sign-in-link {
          margin-top: 8px;
          font-size: 0.8em;
          color: #888888;
          text-align: center;
          font-weight: normal;
        }

        .sign-in-link a {
          color: ${themeColor};
          text-decoration: underline;
          cursor: pointer;
          font-weight: normal;
        }

        .sign-in-link a:hover {
          color: ${hoverColor};
        }

        /* Arrow button style */
        .arrow-button {
          background-color: transparent;
          border: none;
          cursor: pointer;
          padding: 0;
          transition: transform 0.3s ease;
          flex-shrink: 0; /* Prevents arrow from shrinking */
        }

        .arrow-button:hover svg {
          transform: translateX(5px);
        }

        .arrow-button:focus {
          outline: none;
        }

        /* SVG styling */
        .arrow-svg {
          width: 22px;
          height: 22px;
          stroke: ${themeColor};
        }

        /* Toggle button style */
        .toggle-button {
          position: fixed;
          bottom: 20px;
          right: 20px;
          width: 50px;
          height: 50px;
          border-radius: 50%;
          border: none;
          background-color: ${themeColor};
          box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          transition: transform 0.3s ease, background-color 0.3s ease;
        }

        .toggle-button:hover {
          background-color: ${hoverColor};
        }

        /* Toggle button icon style */
        .toggle-icon {
          width: 24px;
          height: 24px;
          stroke: ${themeColor};
          transition: transform 0.3s ease;
        }

        .toggle-button.rotate .toggle-icon {
          transform: rotate(180deg);
        }

        .toggle-button:active .toggle-icon {
          transform: scale(0.9);
        }

        /* Additional content style */
        .additional-content {
          display: none;
          opacity: 0;
          transition: opacity 0.3s ease;
        }

        .additional-content.show {
          display: block;
          opacity: 1;
        }

        /* Welcome card style */
        .welcome-card {
          padding: 16px;
          margin: 8px;
          border: 1px solid #dcdcdc;
          border-radius: 10px;
          background-color: #f9f9f9;
          box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
          display: flex;
          flex-direction: column;
          align-items: center;
          transition: transform 0.3s ease, box-shadow 0.3s ease;
        }

        .welcome-card h4 {
          font-size: 0.95em;
          color: #333333;
          margin-bottom: 8px;
          font-weight: normal;
        }

        .welcome-card p {
          font-size: 0.85em;
          color: #666666;
          margin-bottom: 12px;
          text-align: center;
          font-weight: normal;
        }
      `;

      const styleSheet = document.createElement("style");
      styleSheet.type = "text/css";
      styleSheet.innerText = styles;
      document.head.appendChild(styleSheet);

      const dialog = document.createElement("div");
      dialog.id = "rewards-dialog";

      const headerContainer = document.createElement("div");
      headerContainer.id = "header-container";

      const backArrowButton = document.createElement("button");
      backArrowButton.className = "back-arrow";
      backArrowButton.innerHTML = `
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
        </svg>
      `;
      backArrowButton.addEventListener("click", resetDialogToInitialState);

      const headerImage = document.createElement("img");
      headerImage.id = "header-image";
      headerImage.style.display = "none"; // Initially hidden
      headerContainer.appendChild(headerImage);

      const welcomeText = document.createElement("h2");
      welcomeText.textContent = `Welcome to ${programName}`;
      headerContainer.appendChild(welcomeText);

      headerContainer.appendChild(backArrowButton);

      dialog.appendChild(headerContainer);

      // Main content container
      const mainContent = document.createElement("div");
      mainContent.className = "main-content";

      // Check if user exists and display welcome card
      if (
        userExistData &&
        userExistData.userExist &&
        userData &&
        userData.payload
      ) {
        const user = userData.payload.user;
        const points = userData.payload.points;
        const welcomeCard = document.createElement("div");
        welcomeCard.className = "welcome-card";

        const welcomeMessage = document.createElement("h4");
        welcomeMessage.textContent = `Welcome Back, ${user.name}`;
        welcomeCard.appendChild(welcomeMessage);

        const userDetailsContainer = document.createElement("div");
        userDetailsContainer.style.display = "flex";
        userDetailsContainer.style.flexDirection = "column";
        userDetailsContainer.style.padding = "16px"; // Add proper padding
        userDetailsContainer.style.backgroundColor = "#f4f4f4"; // Background for the container
        userDetailsContainer.style.borderRadius = "8px"; // Add some border-radius for a smoother look
        userDetailsContainer.style.width = "100%"; // Increase width to full container width

        // Create an array of details to make it easier to manage
        const details = [
          { label: "Total Points", value: points.totalAvailable },
          { label: "Total Points Earned", value: points.totalEarned },
          { label: "Total Points Redeemed", value: points.totalRedeemed },
        ];

        details.forEach((detail) => {
          const detailRow = document.createElement("div");
          detailRow.style.display = "flex";
          detailRow.style.justifyContent = "space-between"; // Align text on left and right
          detailRow.style.marginBottom = "4px"; // Reduce spacing between rows

          const label = document.createElement("strong");
          label.textContent = `${detail.label}:`;
          label.style.flex = "0 0 50%"; // Adjust width of label
          label.style.textAlign = "left"; // Align label to the left
          label.style.fontSize = "12px"; // Smaller font size
          label.style.whiteSpace = "nowrap"; // Prevent label from wrapping to next line

          const value = document.createElement("span");
          value.textContent = detail.value;
          value.style.flex = "0 0 50%"; // Adjust width of value
          value.style.textAlign = "right"; // Align value to the right
          value.style.fontSize = "12px"; // Smaller font size
          value.style.whiteSpace = "nowrap"; // Prevent value from wrapping to next line

          detailRow.appendChild(label);
          detailRow.appendChild(value);
          userDetailsContainer.appendChild(detailRow);
        });

        // Add the userDetailsContainer to the welcome card
        welcomeCard.appendChild(userDetailsContainer);

        mainContent.appendChild(welcomeCard);
      }

      function createMemberCard(title, description, buttonText, isJoinNow) {
        const card = document.createElement("div");
        card.className = "memberCard";

        const cardTitle = document.createElement("h6");
        cardTitle.textContent = title;
        card.appendChild(cardTitle);

        const cardDescription = document.createElement("p");
        cardDescription.textContent = description;
        card.appendChild(cardDescription);

        const cardButton = document.createElement("button");
        cardButton.className = "card-button";
        cardButton.textContent = buttonText;
        cardButton.addEventListener("click", () => {
          if (isJoinNow) {
            const currentUrl = window.location.origin;
            window.location.href = `${currentUrl}/auth/login`;
          }
        });

        card.appendChild(cardButton);

        const signInText = document.createElement("div");
        signInText.className = "sign-in-link";
        signInText.innerHTML = `Already have an account? <a id="sign-in-link">Sign In</a>`;

        signInText
          .querySelector("#sign-in-link")
          .addEventListener("click", () => {
            const currentUrl = window.location.origin;
            window.location.href = `${currentUrl}/auth/login`;
          });

        card.appendChild(signInText);

        return card;
      }

      // Function to truncate text with ellipsis
      function truncateText(text, maxLength) {
        if (text.length > maxLength) {
          return text.slice(0, maxLength) + "...";
        }
        return text;
      }

      // Function to create a card with a title, description, and arrow button
      function createCard(title, description, image, eventName = "") {
        const card = document.createElement("div");
        card.className = "card";
        card.style.cursor = "pointer"; // Make the card look clickable

        // Create and append image
        const img = document.createElement("img");
        img.src = image;
        img.alt = title;
        img.style.borderRadius = "50%";
        img.style.width = "40px";
        img.style.height = "40px";
        card.appendChild(img);

        const cardContent = document.createElement("div");
        cardContent.className = "card-content";

        const cardTitle = document.createElement("h6");
        const truncatedTitle = truncateText(title, 30); // Truncate title to max length of 30
        cardTitle.textContent = truncatedTitle;
        cardContent.appendChild(cardTitle);

        const cardDescription = document.createElement("p");
        const truncatedDescription = truncateText(description, 60); // Truncate description to max length of 60
        cardDescription.textContent = truncatedDescription;
        cardDescription.addEventListener("click", (event) => {
          event.stopPropagation(); // Prevent card click event when clicking description
          if (cardDescription.textContent === truncatedDescription) {
            cardDescription.textContent = description; // Expand description on click
          } else {
            cardDescription.textContent = truncatedDescription; // Collapse description on second click
          }
        });
        cardContent.appendChild(cardDescription);

        card.appendChild(cardContent);

        // Conditionally create arrow button only if eventName is empty
        if (!eventName) {
          const arrowButton = document.createElement("button");
          arrowButton.className = "arrow-button";
          arrowButton.innerHTML = `
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="arrow-svg">
              <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
            </svg>
          `;
          arrowButton.addEventListener("click", (event) => {
            event.stopPropagation(); // Prevent card click event when clicking arrow
            switchToAdditionalContent(title);
          });

          card.appendChild(arrowButton);
        }

        // Make the whole card clickable
        card.addEventListener("click", () => {
          switchToAdditionalContent(title);
          if (eventName) {
            const customEvent = new CustomEvent(eventName, {
              detail: { message: "Game event triggered" },
            });
            window.dispatchEvent(customEvent);
            console.log(`Event fired: ${eventName}`);
            toggleDialogVisibility();
          }
        });

        return card;
      }

      function createAdditionalCard(title, description, image) {
        const card = document.createElement("div");
        card.className = "card";

        // Create and append image
        const img = document.createElement("img");
        img.src = image;
        img.alt = title;
        img.style.borderRadius = "50%";
        img.style.width = "40px";
        img.style.height = "40px";
        card.appendChild(img);

        const cardContent = document.createElement("div");
        cardContent.className = "card-content";

        const cardTitle = document.createElement("h6");
        const truncatedTitle = truncateText(title, 30); // Truncate title to max length of 30
        cardTitle.textContent = truncatedTitle;
        cardContent.appendChild(cardTitle);

        const cardDescription = document.createElement("p");
        const truncatedDescription = truncateText(description, 60); // Truncate description to max length of 60
        cardDescription.textContent = truncatedDescription;
        cardDescription.addEventListener("click", () => {
          if (cardDescription.textContent === truncatedDescription) {
            cardDescription.textContent = description; // Expand description on click
          } else {
            cardDescription.textContent = truncatedDescription; // Collapse description on second click
          }
        });
        cardContent.appendChild(cardDescription);

        card.appendChild(cardContent);

        // // Create arrow button for the card
        // const arrowButton = document.createElement("button");
        // arrowButton.className = "arrow-button";
        // arrowButton.innerHTML = `
        //   <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="arrow-svg">
        //     <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
        //   </svg>
        // `;

        // card.appendChild(arrowButton);

        return card;
      }

      // Function to add earn cards to the specified container
      function addEarnCards(container, data) {
        data.orderEarnRulesData.forEach((reward) => {
          const description = `Earn ${reward.rewardValue} points for every purchase. This reward can help you save and accumulate more points efficiently over time.`;
          const card = createAdditionalCard(
            reward.title,
            description,
            "https://cdn.pixelbin.io/v2/frolic/original/Fynd_Engage_Reward_Panel_Icons/Earn.svg"
          );
          container.appendChild(card);
        });
      }

      // Function to add redeem cards to the specified container
      function addRedeemCards(container, data) {
        data.orderRedeemRulesData.forEach((redeemRule) => {
          const description = `Redeem your points for ${redeemRule.title}. This reward requires ${redeemRule.redeemValue} points. By redeeming your points, you get exclusive discounts and offers.`;
          const card = createAdditionalCard(
            redeemRule.title,
            description,
            "https://cdn.pixelbin.io/v2/frolic/original/Fynd_Engage_Reward_Panel_Icons/Redeem.svg"
          );
          container.appendChild(card);
        });
      }

      function addTierCards(container, data) {
        data.tiersData.forEach((tier) => {
          const description = `Unlock exclusive rewards in the ${tier.title} tier when you reach ${tier.thresholdValue} points. Enjoy premium perks, priority access to new features, and more as you elevate your experience.`;
          const card = createAdditionalCard(
            tier.title,
            description,
            "https://cdn.pixelbin.io/v2/frolic/original/Fynd_Engage_Reward_Panel_Icons/Tier.svg"
          );
          container.appendChild(card);
        });
      }

      // Create a function to switch to additional content
      function switchToAdditionalContent(title) {
        // Hide main content
        mainContent.style.display = "none";

        // Show additional content
        additionalContent.style.display = "block";
        additionalContent.classList.add("show");

        // Show back button
        backArrowButton.style.display = "block";

        // Update header text
        welcomeText.textContent = title;

        // Clear previous content
        additionalContent.innerHTML = "";

        // Add dynamic cards to additional content based on title
        if (title === "Ways to Earn") {
          fetchRewardsData().then((data) => {
            addEarnCards(additionalContent, data);
          });
        } else if (title === "Ways to Redeem") {
          fetchRewardsData().then((data) => {
            addRedeemCards(additionalContent, data);
          });
        } else if (title === "Tier") {
          fetchRewardsData().then((data) => {
            addTierCards(additionalContent, data);
          });
        }

        // Scroll to the top of the dialog
        dialog.scrollTop = 0;
      }

      function resetDialogToInitialState() {
        // Show main content
        mainContent.style.display = "block";

        // Hide additional content
        additionalContent.style.display = "none";
        additionalContent.classList.remove("show");

        // Hide back button
        backArrowButton.style.display = "none";

        // Reset header text
        headerImage.style.display = "none";
        welcomeText.textContent = `Welcome to ${programName}`;
        welcomeText.style.display = "block";
      }

      if (userExistData && !userExistData.userExist) {
        const memberCard = createMemberCard(
          "Become a Member",
          "With more ways to unlock exciting perks, this is your all-access pass to exclusive rewards.",
          "Join Now",
          true
        );
        mainContent.appendChild(memberCard);
      }

      // Create initial static cards
      const initialCards = [
        {
          title: "Ways to Earn",
          description:
            "Earn points through activities and purchases that contribute to your total rewards.",
          image:
            "https://cdn.pixelbin.io/v2/frolic/original/Fynd_Engage_Reward_Panel_Icons/Earn.svg",
        },
        {
          title: "Ways to Redeem",
          description:
            "Redeem points for rewards and discounts on your next purchase.",
          image:
            "https://cdn.pixelbin.io/v2/frolic/original/Fynd_Engage_Reward_Panel_Icons/Redeem.svg",
        },
        {
          title: "Tier",
          description:
            "Unlock rewards in each tier to get more benefits and save more on your orders.",
          image:
            "https://cdn.pixelbin.io/v2/frolic/original/Fynd_Engage_Reward_Panel_Icons/Tier.svg",
        },
        {
          title: "Play Slot Machine Game",
          description:
            "Spin to win points and enjoy your favorite games while earning rewards.",
          image:
            "https://cdn.pixelbin.io/v2/frolic/original/Fynd_Engage_Reward_Panel_Icons/slot-machine.svg",
          eventName: PLAY_SLOT_MACHINE,
        },
        {
          title: "Play Frolic Game",
          description:
            "Earn points in Frolic as you engage in exciting challenges and activities.",
          image:
            "https://cdn.pixelbin.io/v2/frolic/original/Fynd_Engage_Reward_Panel_Icons/Frolic.svg",
          eventName: PLAY_FROLIC_GAMES,
        },
      ];

      initialCards.forEach((cardInfo) => {
        const card = createCard(
          cardInfo.title,
          cardInfo.description,
          cardInfo.image,
          cardInfo?.eventName
        );
        mainContent.appendChild(card);
      });

      // Additional content container
      const additionalContent = document.createElement("div");
      additionalContent.className = "additional-content";

      dialog.appendChild(mainContent);
      dialog.appendChild(additionalContent);

      document.body.appendChild(dialog);

      const toggleButton = document.createElement("button");
      toggleButton.className = "toggle-button";
      toggleButton.innerHTML = `
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_2781_30613" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
              <rect width="24" height="24" fill="${themeColor}" />
            </mask>
            <g mask="url(#mask0_2781_30613)">
              <path d="M12 14.475L13.9 15.625C14.0833 15.7417 14.2667 15.7375 14.45 15.6125C14.6333 15.4875 14.7 15.3167 14.65 15.1L14.15 12.925L15.85 11.45C16.0167 11.3 16.0667 11.1208 16 10.9125C15.9333 10.7042 15.7833 10.5917 15.55 10.575L13.325 10.4L12.45 8.35C12.3667 8.15 12.2167 8.05 12 8.05C11.7833 8.05 11.6333 8.15 11.55 8.35L10.675 10.4L8.45 10.575C8.21667 10.5917 8.06667 10.7042 8 10.9125C7.93333 11.1208 7.98333 11.3 8.15 11.45L9.85 12.925L9.35 15.1C9.3 15.3167 9.36667 15.4875 9.55 15.6125C9.73333 15.7375 9.91667 15.7417 10.1 15.625L12 14.475ZM8.65 20H6C5.45 20 4.97917 19.8042 4.5875 19.4125C4.19583 19.0208 4 18.55 4 18V15.35L2.075 13.4C1.89167 13.2 1.75 12.9792 1.65 12.7375C1.55 12.4958 1.5 12.25 1.5 12C1.5 11.75 1.55 11.5042 1.65 11.2625C1.75 11.0208 1.89167 10.8 2.075 10.6L4 8.65V6C4 5.45 4.19583 4.97917 4.5875 4.5875C4.97917 4.19583 5.45 4 6 4H8.65L10.6 2.075C10.8 1.89167 11.0208 1.75 11.2625 1.65C11.5042 1.55 11.75 1.5 12 1.5C12.25 1.5 12.4958 1.55 12.7375 1.65C12.9792 1.75 13.2 1.89167 13.4 2.075L15.35 4H18C18.55 4 19.0208 4.19583 19.4125 4.5875C19.8042 4.97917 20 5.45 20 6V8.65L21.925 10.6C22.1083 10.8 22.25 11.0208 22.35 11.2625C22.45 11.5042 22.5 11.75 22.5 12C22.5 12.25 22.45 12.4958 22.35 12.7375C22.25 12.9792 22.1083 13.2 21.925 13.4L20 15.35V18C20 18.55 19.8042 19.0208 19.4125 19.4125C19.0208 19.8042 18.55 20 18 20H15.35L13.4 21.925C13.2 22.1083 12.9792 22.25 12.7375 22.35C12.4958 22.45 12.25 22.5 12 22.5C11.75 22.5 11.5042 22.45 11.2625 22.35C11.0208 22.25 10.8 22.1083 10.6 21.925L8.65 20ZM9.5 18L12 20.5L14.5 18H18V14.5L20.5 12L18 9.5V6H14.5L12 3.5L9.5 6H6V9.5L3.5 12L6 14.5V18H9.5Z" fill="white"/>
            </g>
          </svg>
      
      `;

      toggleButton.addEventListener("click", toggleDialogVisibility);

      function toggleDialogVisibility() {
        const isDialogVisible = dialog.classList.toggle("show");
        const closeIconSvg = `
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_2797_2807" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
              <rect width="24" height="24" fill="${themeColor}" />
            </mask>
            <g mask="url(#mask0_2797_2807)">
              <path d="M12 13.4L7.10005 18.3C6.91672 18.4834 6.68338 18.575 6.40005 18.575C6.11672 18.575 5.88338 18.4834 5.70005 18.3C5.51672 18.1167 5.42505 17.8834 5.42505 17.6C5.42505 17.3167 5.51672 17.0834 5.70005 16.9L10.6 12L5.70005 7.10005C5.51672 6.91672 5.42505 6.68338 5.42505 6.40005C5.42505 6.11672 5.51672 5.88338 5.70005 5.70005C5.88338 5.51672 6.11672 5.42505 6.40005 5.42505C6.68338 5.42505 6.91672 5.51672 7.10005 5.70005L12 10.6L16.9 5.70005C17.0834 5.51672 17.3167 5.42505 17.6 5.42505C17.8834 5.42505 18.1167 5.51672 18.3 5.70005C18.4834 5.88338 18.575 6.11672 18.575 6.40005C18.575 6.68338 18.4834 6.91672 18.3 7.10005L13.4 12L18.3 16.9C18.4834 17.0834 18.575 17.3167 18.575 17.6C18.575 17.8834 18.4834 18.1167 18.3 18.3C18.1167 18.4834 17.8834 18.575 17.6 18.575C17.3167 18.575 17.0834 18.4834 16.9 18.3L12 13.4Z" fill="white" />
            </g>
          </svg>
        `;

        const openIconSvg = `
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_2781_30613" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
              <rect width="24" height="24" fill="${themeColor}" />
            </mask>
            <g mask="url(#mask0_2781_30613)">
              <path d="M12 14.475L13.9 15.625C14.0833 15.7417 14.2667 15.7375 14.45 15.6125C14.6333 15.4875 14.7 15.3167 14.65 15.1L14.15 12.925L15.85 11.45C16.0167 11.3 16.0667 11.1208 16 10.9125C15.9333 10.7042 15.7833 10.5917 15.55 10.575L13.325 10.4L12.45 8.35C12.3667 8.15 12.2167 8.05 12 8.05C11.7833 8.05 11.6333 8.15 11.55 8.35L10.675 10.4L8.45 10.575C8.21667 10.5917 8.06667 10.7042 8 10.9125C7.93333 11.1208 7.98333 11.3 8.15 11.45L9.85 12.925L9.35 15.1C9.3 15.3167 9.36667 15.4875 9.55 15.6125C9.73333 15.7375 9.91667 15.7417 10.1 15.625L12 14.475ZM8.65 20H6C5.45 20 4.97917 19.8042 4.5875 19.4125C4.19583 19.0208 4 18.55 4 18V15.35L2.075 13.4C1.89167 13.2 1.75 12.9792 1.65 12.7375C1.55 12.4958 1.5 12.25 1.5 12C1.5 11.75 1.55 11.5042 1.65 11.2625C1.75 11.0208 1.89167 10.8 2.075 10.6L4 8.65V6C4 5.45 4.19583 4.97917 4.5875 4.5875C4.97917 4.19583 5.45 4 6 4H8.65L10.6 2.075C10.8 1.89167 11.0208 1.75 11.2625 1.65C11.5042 1.55 11.75 1.5 12 1.5C12.25 1.5 12.4958 1.55 12.7375 1.65C12.9792 1.75 13.2 1.89167 13.4 2.075L15.35 4H18C18.55 4 19.0208 4.19583 19.4125 4.5875C19.8042 4.97917 20 5.45 20 6V8.65L21.925 10.6C22.1083 10.8 22.25 11.0208 22.35 11.2625C22.45 11.5042 22.5 11.75 22.5 12C22.5 12.25 22.45 12.4958 22.35 12.7375C22.25 12.9792 22.1083 13.2 21.925 13.4L20 15.35V18C20 18.55 19.8042 19.0208 19.4125 19.4125C19.0208 19.8042 18.55 20 18 20H15.35L13.4 21.925C13.2 22.1083 12.9792 22.25 12.7375 22.35C12.4958 22.45 12.25 22.5 12 22.5C11.75 22.5 11.5042 22.45 11.2625 22.35C11.0208 22.25 10.8 22.1083 10.6 21.925L8.65 20ZM9.5 18L12 20.5L14.5 18H18V14.5L20.5 12L18 9.5V6H14.5L12 3.5L9.5 6H6V9.5L3.5 12L6 14.5V18H9.5Z" fill="white"/>
            </g>
          </svg>
        `;
        toggleButton.innerHTML = isDialogVisible ? closeIconSvg : openIconSvg;
        toggleButton.classList.toggle("rotate");

        if (!isDialogVisible) {
          resetDialogToInitialState();
        }
      }

      document.body.appendChild(toggleButton);

      document.addEventListener("click", function (event) {
        const isClickInsideDialog = dialog.contains(event.target);
        const isClickInsideToggleButton = toggleButton.contains(event.target);

        if (!isClickInsideDialog && !isClickInsideToggleButton) {
          if (dialog.classList.contains("show")) {
            toggleDialogVisibility();
          }
        }
      });
    })
    .catch((error) => {
      console.error("Error handling promises:", error);
    });
}

window.addEventListener("load", init());
